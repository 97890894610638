<template>
  <NowLiveList />
</template>

<script>
import NowLiveList from "../components/NowLiveList.vue";

export default {
  name: "NowLive",
  components: {
    NowLiveList,
  },
};
</script>
