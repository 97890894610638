<template>
  <Faq />
</template>

<script>
import Faq from "../components/Faq.vue";

export default {
  name: "FaqView",
  components: {
    Faq,
  },
};
</script>
