<template>
  <FinntuberList name="List of Finnish VTubers" />
</template>

<script>
import FinntuberList from "../components/FinntuberList.vue";

export default {
  name: "Browse",
  components: {
    FinntuberList,
  },
};
</script>
